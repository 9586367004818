.App {
  flex: 1;
  text-align: center;
  background: radial-gradient(gold, transparent);
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: black;
  align-items: center;
  justify-content: center;

  &.isMobileBrowser {
    align-items: unset;
    justify-content: unset;
  }
}

.Homepage {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}

a {
  color: blue;
  text-decoration: none;
}

h1,
h2 {
  margin: 0;
}

h1 {
  font-size: 120px;
  color: rgba(black, 0.75);
}

h2 {
  font-size: 80px;
  font-weight: normal;
  color: rgba(black, 0.5);
}

.DeviceWrapper {
  display: flex;
  position: relative;
}

.PopupHeader {
  bottom: calc(100% + 10px);
}

.PopupFooter {
  font-size: 30px;
  color: blue;
  bottom: -90px;
}

.PopupContent {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;

  strong {
    display: inline-flex;
    color: black;
    background: rgba(white, 0.5);
    transform: scale(1);
    animation: explode 0.5s;
    animation-delay: 2s;
  }
}

.PopupHeader .PopupContent {
  color: #000;
  width: 400px;

  .PopupContentTitle {
    font-size: 50px;
    font-weight: bold;
    white-space: nowrap;
  }

  .PopupContentMore {
    font-size: 20px;
    line-height: 1.5em;
    color: rgba(black, 0.5);
  }
}

.PopupHeader {
  .LogoWord {
    --OUTLINE-WIDTH: 5px;
  }
}

.PopupFooter .PopupContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  white-space: nowrap;
}

.Popup {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.2s;
}

.PopupFinger {
  pointer-events: none;
  font-size: 50px;
  position: absolute;
  left: auto;
  right: 0;
  bottom: 200px;

  //
  transform: translateX(-50px) scale(2.5) rotate(45deg);
  animation-delay: 2s !important;
  animation: animatePopupFinger 3s infinite;
}

@keyframes explode {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(1.25);
  }

  100% {
    transform: scale(1);
  }
}

@media only screen and (max-width: 400px) {
  // do something in this width range.
  .App {
    align-items: unset;
    justify-content: unset;
  }

  .DeviceWrapper {
    flex: 1;
    overflow: hidden;
  }

  .Device {
    width: 100vw;
    height: 100vh;
    padding: 0;
    border-radius: 0;

    .Screen {
      border-radius: 0;
    }
  }

  .StatusBar,
  .DeviceControls {
    display: none !important;
  }
}

@media only screen and (min-width: 400px) {
  // do something in this width range.
  .Popup {
    opacity: 1;
  }
}

@keyframes animatePopupFinger {
  0%,
  100% {
    transform: translateX(-50px) scale(2.5) rotate(45deg);
  }
  50% {
    transform: translateX(0) scale(3) rotate(60deg);
  }
}
