.LisdFrame {
  flex: 1;
  background: black;
  border: 0;
  display: flex;

  iframe {
    border: 0;
    flex: 1;
  }
}

.isDeviceOff .LisdFrame {
  display: none;
}
