.Device {
  display: flex;
  position: relative;
  flex-direction: column;

  height: 700px;
  width: 360px;
  background-color: black;
  padding: 10px;
  border-radius: 20px;

  // https://medium.com/@jeandesravines/use-border-radius-and-outline-simultaneously-on-safari-14ce92889e1f
  &::before {
    content: "";
    position: absolute;
    top: -10px;
    right: -10px;
    bottom: -10px;
    left: -10px;
    border: 10px solid pink;
    border-radius: 30px;
  }

  .Screen {
    display: flex;
    flex: 1;
    position: relative;
    flex-direction: column;

    overflow: hidden;

    background: rgba(255, 255, 255, 0.2);
  }

  .StatusBar {
    display: flex;
    flex-direction: row;
    padding: 10px 10px;
    background: rgba(0, 0, 0, 0.75);
    font-size: 20px;
    filter: drop-shadow(2px);
  }
}

.AboutScreen {
  display: flex;
  color: white;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.ScreenContent {
  display: flex;
  flex: 1;
  position: relative;
}
