.About {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 25px;

  padding: 30px;

  background: rgba(white, 0.0625);

  align-items: center;
  justify-content: center;

  color: white;

  .LogoWord {
    --OUTLINE-WIDTH: 15px;
  }
}
